import React from 'react'
import { StaffGrid, useModal, EmployeeType } from '@islandsstofa/react'

const Staff: React.FC<{ staffMembers: EmployeeType[] }> = ({
  staffMembers
}) => {
  const { executeModal } = useModal()

  const runModal = (staffIdx: number) => {
    executeModal(staffMembers, staffIdx, 'modal__staff', 'Staff')
  }

  return (
    <StaffGrid
      title='Staff'
      members={staffMembers}
      modalExecutable={runModal}
    />
  )
}

export default Staff
