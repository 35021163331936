import React from 'react'
import { Animate } from '@islandsstofa/react'
import cn from 'classnames'

type Props = {
  headline: { text: string }
  video: {
    url: string
  }
}

const HeroTopVideo: React.FC<Props> = ({ headline, video }) => {
  const split = headline.text.split(' ')

  const INITIAL_ANIM_DELAY = 1.2
  const STAGGER = 0.2

  return (
    <div className='hero-top-video page-slice-spacer border position-relative'>
      <video muted autoPlay playsInline loop src={video.url} />
      <div className='hero-top-video__headline-container'>
        {split.map((text, i) => (
          <Animate
            key={i}
            type='slide-up'
            delay={INITIAL_ANIM_DELAY + i * STAGGER}
            duration={750}
          >
            <h1
              className={cn({
                'pt-2': i === 0
              })}
            >
              {text}
            </h1>
          </Animate>
        ))}
      </div>
    </div>
  )
}

export default HeroTopVideo
