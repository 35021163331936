import React from 'react'
import styles from './Chart.module.scss'
import { AspectRatioBox } from '@islandsstofa/react'
import cn from 'classnames'

type ChartProps = { title: string; chartURL: string }

const Chart: React.FC<ChartProps> = ({ title = '', chartURL = '' }) => {
  return (
    <div className='container page-slice-spacer__smaller'>
      <h3 className={cn(styles.title, 'mb-3')}>{title}</h3>
      <AspectRatioBox
        containerClass={styles.overrideAspectRatio}
        ratio='widescreen'
      >
        <iframe
          src={chartURL}
          width='100%'
          height='85%'
          scrolling='no'
          allowFullScreen
          className={styles.chart}
        ></iframe>
      </AspectRatioBox>
    </div>
  )
}

export default Chart
