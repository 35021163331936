import React from 'react'
import { Container } from '@kogk/common'
import SliceWrapper from '@cmp/site/SliceWrapper'

interface BoardMember {
  full_name: string
  role: string
}

const BoardList: React.FC<{ title: string; boardMembers: BoardMember[] }> = ({
  title,
  boardMembers
}) => {
  return (
    <Container className='mb-4'>
      <SliceWrapper shouldMove>
        <h5 className='mb-3'>{title}</h5>
        {boardMembers.map((member, idx) => (
          <div key={idx} className='mb-2'>
            <p className='font-weight-bold m-0 parag'>{member.full_name}</p>
            <p className='parag'>{member.role}</p>
          </div>
        ))}
      </SliceWrapper>
    </Container>
  )
}

export default BoardList
