import React from 'react'
import SearchResults from '@cmp/site/SearchResults'
import Chart from '@cmp/site/Chart'

type Props = {
  programName: string
  options: unknown[]
}

const System = ({ programName, options }: Props) => {
  const programs: { [key: string]: React.ElementType } = {
    SearchResults,
    Chart
  }
  if (programs[programName]) {
    const Cmp = programs[programName]
    return <Cmp {...options} />
  } else {
    return <div>unhandled {programName}</div>
  }
}

export default System
