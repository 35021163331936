import React from 'react'
import { graphql } from 'gatsby'
import { usePreview, makeSlices } from '@kogk/gatsby-theme-prismic'
import '@src/data/prismic-fragments/page'
import MainLayout from '@root/src/components/layout/MainLayout'
import sliceMapping from '@src/data/prismic-slice-mapping/page-slices'
import { GlobalDataProvider } from '@src/data/global-data'
const Slices = makeSlices(sliceMapping)

const Page = ({ data: staticData, location }) => {
  const data = usePreview(staticData)

  const language = data.prismicPage.lang.substring(0, 2)

  const altUrls = [
    ...data.prismicPage.alternate_languages.map(({ document }) => document),
    ...(location
      ? [
          {
            url: location.pathname,
            lang: language
          }
        ]
      : [])
  ]

  const seoProperties = data.prismicPage.data.seo_properties

  return (
    <GlobalDataProvider language={language} altUrls={altUrls}>
      <MainLayout>
        <article className='page-wrap'>
          <Slices body={data.prismicPage.data.body} />
        </article>
      </MainLayout>
    </GlobalDataProvider>
  )
}

export default Page

export const query = graphql`
  query genericPageQuery($prismicId: ID!) {
    prismicPage(prismicId: { eq: $prismicId }) {
      ...prismicPageFragmentFull
    }
  }
`
