import System from '@cmp/sections/System'
import Staff from '@cmp/sections/Staff'
import BoardList from '@cmp/sections/BoardList'
import {
  Feature,
  RichText,
  HeroEpic,
  HeroHeader,
  HeroMedia,
  InformationBanner,
  SlimFeature,
  StatisticsBanner,
  Table,
  Button,
  HeroMediaType
} from '@islandsstofa/react'
import HeroTopVideo from '@cmp/sections/HeroTopVideo'
import { gsap } from 'gsap'
import { Link } from 'gatsby'
import React from 'react'
import Img from 'gatsby-image'

export default {
  PrismicPageBodyHeroCinematic: {
    component: HeroTopVideo,
    dataToProps(data: any) {
      return {
        ...data.primary
      }
    }
  },

  PrismicPageBodyInformationBanner: {
    component: InformationBanner,
    dataToProps(data: any) {
      const items = data.items.map((item: any) => {
        return {
          title: item.group_name,
          statistics: [
            { title: item.statistic_one_name, value: item.statistic_one_value },
            { title: item.statistic_two_name, value: item.statistic_two_value }
          ]
        }
      })

      return { items }
    }
  },

  PrismicPageBodyBoardList: {
    component: BoardList,
    dataToProps(data: any) {
      return {
        title: data.primary.boardTitle,
        boardMembers: data.items
      }
    }
  },

  PrismicPageBodyStaff: {
    component: Staff,
    dataToProps(data: any) {
      return {
        staffMembers: data.items
      }
    }
  },

  PrismicPageBodyRichText: {
    component: RichText,
    dataToProps(data: any) {
      return {
        html: data.primary.rich_text.html
      }
    }
  },

  PrismicPageBodyHeroMedia: {
    component: HeroMedia,
    dataToProps(data: any): HeroMediaType {
      const {
        primary: {
          caption,
          single_image: singleImage,
          video,
          firstonpage: firstOnPage,
          see_gallery_message: btnLabel
        },
        items: gallery
      } = data
      return {
        caption,
        singleImage,
        video,
        gallery,
        firstOnPage,
        btnLabel,
        onRenderImageComponent: () => (
          <Img
            fluid={singleImage.fluid}
            alt={singleImage.alt}
            className='image-rounded'
          />
        )
      }
    }
  },

  PrismicPageBodyHeroEpic: {
    component: HeroEpic,
    dataToProps(data: any) {
      const {
        primary: { image, title, text, video, link_text, link_to_page }
      } = data

      return {
        title: title.text,
        text: text.text,
        image,
        video,
        link: {
          text: link_text,
          url: link_to_page?.url
        },
        gsap,
        onRenderImage: () => (
          <Img fluid={image.fluid} alt={image.alt} className='h-100 w-100' />
        )
      }
    }
  },

  PrismicPageBodyFeature: {
    component: Feature,
    dataToProps(data: any) {
      const {
        primary: {
          text: { html },
          image,
          image_on_right: imageOnRight,
          caption
        }
      } = data

      return {
        html,
        image,
        imageOnRight,
        caption
      }
    }
  },

  PrismicPageBodySlimFeature: {
    component: SlimFeature,
    dataToProps(data: any) {
      const {
        primary: {
          text: { html },
          image,
          link_text,
          link_to_page
        }
      } = data
      return {
        html,
        image: {
          alt: image?.alt,
          url: image?.fluid.src
        },
        imageTag: link_to_page?.document.tags[0],
        link: {
          text: link_text,
          url: link_to_page?.url
        }
      }
    }
  },

  PrismicPageBodySystem: {
    component: System,
    dataToProps(data: any) {
      return {
        programName: data.primary.program,
        options: data.items
          .filter(
            ({ parameter, value }: { parameter: string; value: string }) =>
              parameter && value !== null
          )
          .reduce((acc: any, item: { value: string; parameter: string }) => {
            acc[item.parameter] = item.value
            return acc
          }, {})
      }
    }
  },

  PrismicPageBodyStatisticsBanner: {
    component: StatisticsBanner,
    dataToProps(data: any) {
      const {
        primary: {
          stat_category,
          stat_title,
          stat_description,
          link_text,
          link_to_page
        }
      } = data
      return {
        category: stat_category,
        title: stat_title,
        description: stat_description,
        link: {
          text: link_text,
          url: link_to_page?.url
        },
        statistics: data.items.map((s: any) => {
          return {
            statisticName: s.statistic_name,
            statisticValue: s.statistic_value
          }
        }),
        onRenderLink: () => (
          <Link to={link_to_page?.url ?? '/'}>
            <Button label={link_text} type='secondary' icon='arrowRight' />
          </Link>
        )
      }
    }
  },

  PrismicPageBodyTable: {
    component: Table,
    dataToProps(data: any) {
      const {
        primary: { table_title: title }
      } = data
      return {
        title,
        rows: data.items.map((d: any) => {
          return {
            left: d.row_left_value,
            right: d.row_right_value
          }
        })
      }
    }
  },

  PrismicPageBodyHeroHeader: {
    component: HeroHeader,
    dataToProps(data: any) {
      const {
        primary: {
          text: { html },
          image
        }
      } = data
      return {
        html,
        image
      }
    }
  }
}
